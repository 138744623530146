

console.log('ASDF');
$(document).ready(function() {
    $("body").on("click", ".drop-item.opener", function() {
        console.log("ASDF");
        $(this).closest(".drop-container").find(".custom-dropdown").show()
    })

    $("body").on("click", ".drop-item[data-lang]", function() {
        var lang = $(this).attr('data-lang') 
        lang = lang == "en" || lang == "ja" ? lang : "en"

        const newQueryParam = "?locale=" + lang; 
        var url = window.location.href.split('?')[0];
        const modifiedURL = url + newQueryParam;
        window.history.replaceState(null, document.title, modifiedURL)
        location.reload();
    })

    $("body").on("click", function(e) {
        if($(e.target).closest(".drop-container").length > 0 || $(e.target).is(".drop-container")){
        }else{
            $(".custom-dropdown").hide()
        }
    })
})
 